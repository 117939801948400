<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Assuming each of the following solutions are at
        <stemble-latex content="$25^o\text{C,}$" />
        determine the missing information.
      </p>

      <table class="table table-sm table-hover border mb-5" style="width: 650px">
        <thead class="thead-dark text-center br">
          <th style="width: 8%; vertical-align: middle; height: 50px" />
          <th style="width: 23%; vertical-align: middle; height: 50px">
            <chemical-latex content="[H3O+] (M)" />
          </th>
          <th style="width: 23%; vertical-align: middle; height: 50px">
            <chemical-latex content="[OH-] (M)" />
          </th>
          <th style="width: 23%; vertical-align: middle; height: 50px">
            <stemble-latex content="$\text{pH}$" />
          </th>
          <th style="width: 23%; vertical-align: middle; height: 50px">
            <stemble-latex content="$\text{pOH}$" />
          </th>
        </thead>

        <tbody>
          <tr>
            <td style="height: 15px">
              <stemble-latex content="$\text{a)}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.h3oconc1"
                group-size="sm"
                class="centered-input"
                style="padding: 5px"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.ohconc1"
                group-size="sm"
                class="centered-input"
                style="padding: 5px"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <number-value :value="pH1" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.pOH1"
                group-size="sm"
                class="centered-input"
                style="padding: 5px"
                :show-scientific-notation="false"
              />
            </td>
          </tr>
          <tr>
            <td style="height: 15px">
              <stemble-latex content="$\text{b)}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.h3oconc2"
                group-size="sm"
                class="centered-input"
                style="padding: 5px"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.ohconc2"
                group-size="sm"
                class="centered-input"
                style="padding: 5px"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.pH2"
                group-size="sm"
                class="centered-input"
                style="padding: 5px"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <number-value :value="pOH2" />
            </td>
          </tr>
          <tr>
            <td style="height: 15px">
              <stemble-latex content="$\text{c)}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.h3oconc3"
                group-size="sm"
                class="centered-input"
                style="padding: 5px"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <number-value :value="ohconc3" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.pH3"
                group-size="sm"
                class="centered-input"
                style="padding: 5px"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.pOH3"
                group-size="sm"
                class="centered-input"
                style="padding: 5px"
                :show-scientific-notation="false"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question309',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        ohconc1: null,
        h3oconc1: null,
        pOH1: null,
        h3oconc2: null,
        ohconc2: null,
        pH2: null,
        h3oconc3: null,
        pH3: null,
        pOH3: null,
      },
    };
  },
  computed: {
    pH1() {
      return this.taskState.getValueBySymbol('pH1').content;
    },
    pOH2() {
      return this.taskState.getValueBySymbol('pOH2').content;
    },
    ohconc3() {
      return this.taskState.getValueBySymbol('ohconc3').content;
    },
  },
};
</script>
<style scoped>
td {
  vertical-align: center;
  text-align: center;
}
</style>
<style>
.centered-input input {
  text-align: center;
}
</style>
